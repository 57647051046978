import React from 'react';
import { useTheme } from '../layout/Theme';
import { FaMoon, FaSun} from 'react-icons/fa';
import styles from './ThemeButton.module.css'

const ThemeButton = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button className={styles.theme_button} onClick={toggleTheme}>
      {theme === 'light' ? <FaMoon className={styles.theme_moon}/> : <FaSun className={styles.theme_sun}/>}
    </button>
  );
};

export default ThemeButton