import React from 'react';
import RouletteCardCollaborators from './RouletteCardCollaborators';
import styles from './CardRenderCollaborators.module.css'; // Importando o arquivo CSS

const CardRenderCollaborators = ({ cards }) => {
  return (
    <div className={styles.cardRender}>
      {cards.map((card, index) => (
        <RouletteCardCollaborators key={index} card={card} />
      ))}
    </div>
  );
};

export default CardRenderCollaborators;