// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CardBoard.module.css */
.CardRenderBoardPartners_card-board__ivzYo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 50%;
    max-width: 95%;
}
  
.CardRenderBoardPartners_card__xTVTf {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    width: 200px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/transparency/CardRenderBoardPartners.module.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":["/* CardBoard.module.css */\r\n.card-board {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    min-width: 50%;\r\n    max-width: 95%;\r\n}\r\n  \r\n.card {\r\n    border: 1px solid #ccc;\r\n    border-radius: 8px;\r\n    padding: 16px;\r\n    width: 200px;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-board": `CardRenderBoardPartners_card-board__ivzYo`,
	"card": `CardRenderBoardPartners_card__xTVTf`
};
export default ___CSS_LOADER_EXPORT___;
