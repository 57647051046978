// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Download_item__lz46B {
    margin-left: 5mm;
    margin-bottom: 5mm;
    list-style: none;
    align-items: center;
  }
  
  .Download_button__u6dJ9 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #c8c8c8;
    border: none;
    padding: 10px 20px;
    color: #000;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
  }
  
  .Download_button__u6dJ9:hover {
    background-color: #b3b3b3;
  }
  
  .Download_icon__XDw-2 {
    margin-right: 10px;
  }
  
  .Download_downloadIcon__ijbF\\+ {
    font-size: 20px;
  }
  
  .Download_downloadIcon__ijbF\\+:hover {
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/evento/Download.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,eAAe;IACf,sCAAsC;IACtC,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".item {\r\n    margin-left: 5mm;\r\n    margin-bottom: 5mm;\r\n    list-style: none;\r\n    align-items: center;\r\n  }\r\n  \r\n  .button {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    width: 100%;\r\n    background-color: #c8c8c8;\r\n    border: none;\r\n    padding: 10px 20px;\r\n    color: #000;\r\n    font-size: 18px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s ease;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .button:hover {\r\n    background-color: #b3b3b3;\r\n  }\r\n  \r\n  .icon {\r\n    margin-right: 10px;\r\n  }\r\n  \r\n  .downloadIcon {\r\n    font-size: 20px;\r\n  }\r\n  \r\n  .downloadIcon:hover {\r\n    cursor: pointer;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `Download_item__lz46B`,
	"button": `Download_button__u6dJ9`,
	"icon": `Download_icon__XDw-2`,
	"downloadIcon": `Download_downloadIcon__ijbF+`
};
export default ___CSS_LOADER_EXPORT___;
