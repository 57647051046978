import React from 'react';
import styles from './Home.module.css';

function Home() {
  return (
    <div>
      <li className={styles.home_background}>
        <div className={styles.home_container}>
          <h1>Inicio</h1>
          <p>Você está na página Inicial.</p>
        </div>
      </li>
    </div>
  );
}

export default Home;