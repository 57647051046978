import styles from './News.module.css'
import {Link} from 'react-router-dom'
import { FaHome } from "react-icons/fa";

function News() {
    return(
        <div>
            <div class={styles.path_border}>
                <li class={styles.path}>
                    <Link to="/"><FaHome/>Inicio</Link><span> / Noticias</span>
                </li>
            </div>

            <li className={styles.news_background}>
            <div className={styles.news_container}>
                <h1>
                Noticias
                </h1>
                 <p>
                    Você está na pagina de Noticias.
                </p>
                        
                </div>
                
            </li>
        </div>

    )

}

export default News