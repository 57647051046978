import React from 'react';
import styles from './RouletteCardBoardPartners.module.css'; // Importe o arquivo CSS

const RouletteCardBoardPartners = ({ card }) => {
  return (
    <div className={styles.card}> {/* Use a classe CSS do arquivo CSS */}
      <div className={styles.cardAlign}>
        <p>{card.nome}</p>
        <img src={card.logo} alt={card.nome} />
      </div>
    </div>
  );
};

export default RouletteCardBoardPartners;