import React from 'react';
import RouletteCardBoardPartners from './RouletteCardBoardPartners.js';
import styles from './CardRenderBoardPartners.module.css'; // Importando o arquivo CSS

const CardRenderBoardPartners = ({ cards }) => {
  return (
    <div className={styles.cardRender}>
      {cards.map((card, index) => (
        <a key={index} href={card.link} className={styles.cardLink} target="_blank" rel="noopener noreferrer">
          <RouletteCardBoardPartners card={card} />
        </a>
      ))}
    </div>
  );
};

export default CardRenderBoardPartners;