// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThemeButton_theme_button__uyBLq {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--themebutton-background-color);
  margin-right: 2px;
  padding: 4px;
  margin-left: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 50px;
  font-size: 30px;
  border: none;
}

.ThemeButton_theme_button__uyBLq:hover {
  color: black;
  background-color: #8ca9de;
  cursor: pointer;
}

.ThemeButton_theme_sun__PAJ9W{
  color:yellow;
}

.ThemeButton_theme_moon__owUlS{
  color:lightsteelblue;
}`, "",{"version":3,"sources":["webpack://./src/components/evento/ThemeButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,qDAAqD;EACrD,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,uCAAuC;EACvC,iBAAiB;EACjB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".theme_button {\r\n  display: flex;\r\n  justify-content: space-around;\r\n  align-items: center;\r\n  background-color: var(--themebutton-background-color);\r\n  margin-right: 2px;\r\n  padding: 4px;\r\n  margin-left: 20px;\r\n  border-radius: 20px;\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n  margin-left: 50px;\r\n  font-size: 30px;\r\n  border: none;\r\n}\r\n\r\n.theme_button:hover {\r\n  color: black;\r\n  background-color: #8ca9de;\r\n  cursor: pointer;\r\n}\r\n\r\n.theme_sun{\r\n  color:yellow;\r\n}\r\n\r\n.theme_moon{\r\n  color:lightsteelblue;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theme_button": `ThemeButton_theme_button__uyBLq`,
	"theme_sun": `ThemeButton_theme_sun__PAJ9W`,
	"theme_moon": `ThemeButton_theme_moon__owUlS`
};
export default ___CSS_LOADER_EXPORT___;
