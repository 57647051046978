// Código Roulette
import React, { useState } from 'react';
import CardRenderCollaborators from './CardRenderCollaborators';
import styles from './RouletteCollaborators.module.css'; // Importando o arquivo CSS
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const RouletteCollaborators = () => {
  const initialCards = [
    { funcao: 'DIRETORA', foto: require('../../img/colaboradores/funcionarios/user_giuliana.jpeg'), cargo: 'Giuliana Calvacante de Sousa Castro' },
    { funcao: 'SECRETÁRIA', foto: require('../../img/colaboradores/funcionarios/user_juliana.jpeg'), cargo: 'Juliana Mirando Cunha' },
    { funcao: 'COZINHEIRA', foto: require('../../img/colaboradores/funcionarios/user_genir.jpeg'), cargo: 'Genir Aparecida dos Santos' },
    { funcao: 'PROFESSORA', foto: require('../../img/colaboradores/funcionarios/user_angelica.jpeg'), cargo: 'Angélica Yoshizaki Merenda Batista' },
    { funcao: 'PROFESSORA', foto: require('../../img/colaboradores/funcionarios/user_marcia.jpeg'), cargo: 'Marcia Aparecida dos Santos Ferreira' },
    { funcao: 'PROFESSORA', foto: require('../../img/colaboradores/funcionarios/user_.jpg'), cargo: 'Mariana Souza de Almeida' },
    { funcao: 'PROFESSORAL', foto: require('../../img/colaboradores/funcionarios/user_raquel.jpeg'), cargo: 'Raquel Araujo da Silva' },
    { funcao: 'PROFESSORA', foto: require('../../img/colaboradores/funcionarios/user_daiane.jpeg'), cargo: 'Daiane Angelica de Queiroz' },
    { funcao: 'AUX. PROFESSORA', foto: require('../../img/colaboradores/funcionarios/user_.jpg'), cargo: 'Hoselia Candido Luciano Silva' },
    { funcao: 'PAJEM', foto: require('../../img/colaboradores/funcionarios/user_cilmara.jpeg'), cargo: 'Cilmara Bernardo' },
    { funcao: 'PAJEM', foto: require('../../img/colaboradores/funcionarios/user_rosangela.jpeg'), cargo: 'Rosângela Regina de Oliveira Tridico' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const visibleCards = initialCards.slice(currentIndex, currentIndex + 4);

  const moveLeft = () => {
    setCurrentIndex(prevIndex => {
      if (prevIndex === 0) {
        return initialCards.length - 4;
      }
      return prevIndex - 1;
    });
  };

  const moveRight = () => {
    setCurrentIndex(prevIndex => {
      if (prevIndex === initialCards.length - 4) {
        return 0;
      }
      return prevIndex + 1;
    });
  };

  return (
    <div className={styles.roulette}>
      <button className={styles.buttonLeft} onClick={moveLeft}><FaAngleLeft/></button>
      <CardRenderCollaborators cards={visibleCards} />
      <button className={styles.buttonRight} onClick={moveRight}><FaAngleRight/></button>
    </div>
  );
};

export default RouletteCollaborators;