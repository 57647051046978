import styles from './docs.module.css'
import React from 'react';
import DownloadPDF from '../components/evento/Download.js';

const FunctionDocs_2021 = () => {
  const pdfFileName01 = 'Ata de Assembleia Geral de Posse da Diretoria.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath01 = '/docs/2021/'; // Substitua pelo subdiretório desejado

  const pdfFileName02 = 'Balancete de Verificação.pdf';
  const pdfFilePath02 = '/docs/2021/';

  const pdfFileName03 = 'Declaração Quanto a Economicidade.pdf';
  const pdfFilePath03 = '/docs/2021/';

  const pdfFileName04 = 'Demonstrativo Integral das Receitas e Despesas Estadual.pdf';
  const pdfFilePath04 = '/docs/2021/';

  const pdfFileName05 = 'Demonstrativo Integral das Receitas e Despesas Federal.pdf';
  const pdfFilePath05 = '/docs/2021/';

  const pdfFileName06 = 'Estatuto da Associação Beneficente Fonte Viva.pdf';
  const pdfFilePath06 = '/docs/2021/';

  const pdfFileName07 = 'Plano de Trabalho FUNDEB.pdf';
  const pdfFilePath07 = '/docs/2021/';

  const pdfFileName08 = 'Plano de Trabalho PNAE.pdf';
  const pdfFilePath08 = '/docs/2021/';

  const pdfFileName09 = 'Relação de Prestadores de Serviços.pdf';
  const pdfFilePath09 = '/docs/2021/';

  const pdfFileName10 = 'Relação Nominal de Dirigentes.pdf';
  const pdfFilePath10 = '/docs/2021/';

  const pdfFileName11 = 'Relação Prestadores PNAE.pdf';
  const pdfFilePath11 = '/docs/2021/';

  const pdfFileName12 = 'Relatório de Execução Financeira - Pagamentos.pdf';
  const pdfFilePath12 = '/docs/2021/';

  const pdfFileName13 = 'Retificação Plano de Trabalho 2021.pdf';
  const pdfFilePath13 = '/docs/2021/';

  const pdfFileName14 = 'Termo de Colaboração PNAE.pdf';
  const pdfFilePath14 = '/docs/2021/';

  const pdfFileName15 = 'Termo de Colaboração.pdf';
  const pdfFilePath15 = '/docs/2021/';

  const pdfFileName16 = 'Valores Repassados PNAE.pdf';
  const pdfFilePath16 = '/docs/2021/';

  const pdfFileName17 = 'Valores Repassados.pdf';
  const pdfFilePath17 = '/docs/2021/';

  return (
    <li className={styles.docs_background}>
      <div className={styles.docs_container}>
        <DownloadPDF pdfFileName={pdfFileName01} pdfFilePath={pdfFilePath01} />
        
        <DownloadPDF pdfFileName={pdfFileName02} pdfFilePath={pdfFilePath02} />

        <DownloadPDF pdfFileName={pdfFileName03} pdfFilePath={pdfFilePath03} />
        
        <DownloadPDF pdfFileName={pdfFileName04} pdfFilePath={pdfFilePath04} />

        <DownloadPDF pdfFileName={pdfFileName05} pdfFilePath={pdfFilePath05} />
        
        <DownloadPDF pdfFileName={pdfFileName06} pdfFilePath={pdfFilePath06} />

        <DownloadPDF pdfFileName={pdfFileName07} pdfFilePath={pdfFilePath07} />
        
        <DownloadPDF pdfFileName={pdfFileName08} pdfFilePath={pdfFilePath08} />

        <DownloadPDF pdfFileName={pdfFileName09} pdfFilePath={pdfFilePath09} />
        
        <DownloadPDF pdfFileName={pdfFileName10} pdfFilePath={pdfFilePath10} />

        <DownloadPDF pdfFileName={pdfFileName11} pdfFilePath={pdfFilePath11} />
        
        <DownloadPDF pdfFileName={pdfFileName12} pdfFilePath={pdfFilePath12} />

        <DownloadPDF pdfFileName={pdfFileName13} pdfFilePath={pdfFilePath13} />
        
        <DownloadPDF pdfFileName={pdfFileName14} pdfFilePath={pdfFilePath14} />

        <DownloadPDF pdfFileName={pdfFileName15} pdfFilePath={pdfFilePath15} />
        
        <DownloadPDF pdfFileName={pdfFileName16} pdfFilePath={pdfFilePath16} />

        <DownloadPDF pdfFileName={pdfFileName17} pdfFilePath={pdfFilePath17} />


      </div>
    </li>

  );
};

export default FunctionDocs_2021