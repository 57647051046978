import styles from './docs.module.css'

export function NameDocs_2021() {
    return(

                <p className={styles.docs_item}>
                    Documentos 2021
                </p>

    )

}

export function NameDocs_2022() {
    return(

                <p className={styles.docs_item}>
                    Documentos 2022
                </p>

    )

}

export function NameDocs_2023() {
    return(

                <p className={styles.docs_item}>
                    Documentos 2023
                </p>

    )

}