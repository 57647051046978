import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Contact from './paginas/Contact';
import History from './paginas/History';
import Home from './paginas/Home';
import News from './paginas/News'
import Transparency from './paginas/Transparency';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Events from './paginas/Events';
import LoginForm from './paginas/Login';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/transparencia" element={<Transparency />} />

        <Route path="/historia" element={<History />} />

        <Route path="/noticias" element={<News />} />

        <Route path="/eventos" element={<Events />} />

        <Route path="/contato" element={<Contact />} />

        <Route path="/entrar" element={<LoginForm />} />
          
      </Routes>
      <Footer />

    </Router>
    
  );
}

export default App;