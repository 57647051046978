import React, { useState } from 'react';
import styles from './TransparencyExpansiveList.module.css';
import ExpansiveList from './ExpansiveList';
import RouletteBoard from './RouletteBoard';
import RouletteCollaborators from './RouletteCollaborators';
import RouletteBoardPartners from './RouletteBoardPartners';

const TransparencyListExpansive = () => {
  const [expandido, setExpandido] = useState({
    documentos: false,
    administracao: false,
    contabilidade: false,
    planoAcao: false,
    parcerias: false,
  });

  const handleToggleExpansao = (secao) => {
    setExpandido((prevExpandido) => ({
      ...Object.fromEntries(Object.keys(prevExpandido).map(key => [key, false])),
      [secao]: !prevExpandido[secao],
    }));
  };

  const getButtonClass = (secao) => {
    return expandido[secao] ? styles.selected_button : styles.button_layout;
  };

  return (
    <div>
      <div>
        <button className={`${styles.buttonClass} ${getButtonClass('documentos')}`} onClick={() => handleToggleExpansao('documentos')}>Documentos</button>
        <button className={`${styles.buttonClass} ${getButtonClass('administracao')}`} onClick={() => handleToggleExpansao('administracao')}>Administração</button>
        <button className={`${styles.buttonClass} ${getButtonClass('contabilidade')}`} onClick={() => handleToggleExpansao('contabilidade')}>Contabilidade</button>
        <button className={`${styles.buttonClass} ${getButtonClass('planoAcao')}`} onClick={() => handleToggleExpansao('planoAcao')}>Plano de Ação</button>
        <button className={`${styles.buttonClass} ${getButtonClass('parcerias')}`} onClick={() => handleToggleExpansao('parcerias')}>Parcerias</button>
      </div>

      <div>
        {expandido.documentos && (
          <ul className={styles.expansive_layout}>
            <li>
              <div>
                <div>
                  <h1 className={styles.title_docs}>Documentos</h1>
                  <ExpansiveList/>

                </div>
              </div>
            </li>
          </ul>
        )}

        {expandido.administracao && (
          <ul className={styles.expansive_layout}>
            <li>
              <div className={styles.centerroulette}>
                <div>
                  <h1 className={styles.title_docs}>Diretoria Eleita</h1>
                  <p>2023 A 2025</p>
                  <RouletteBoard/>

                  <h1 className={styles.title_docs}>Quadro De Funcionários:</h1>
                  <RouletteCollaborators/>
                </div>

              </div>
            </li>
          </ul>
        )}

        {expandido.contabilidade && (
          <ul className={styles.expansive_layout}>
          <li>
            <div>
              <div>
                <h1 className={styles.title_docs}>Conteudo Contabilidade</h1>
                

              </div>
            </div>
          </li>
        </ul>
        )}

        {expandido.planoAcao && (
          <ul className={styles.expansive_layout}>
          <li>
            <div>
              <div>
                <h1 className={styles.title_docs}>Conteudo Plano de Ação</h1>
                

              </div>
            </div>
          </li>
        </ul>
        )}

        {expandido.parcerias && (
          <ul className={styles.expansive_layout}>
            <li></li>
            <div className={styles.centerroulette}>
                <div>
                  <h1 className={styles.title_docs}>Empresas Parceiras</h1>
                  <RouletteBoardPartners/>
                </div>

              </div>
          </ul>
        )}
      </div>
    </div>
  );
};

export default TransparencyListExpansive;