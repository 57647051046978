// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransparencyExpansiveList_expansive_layout__N1M0y{
    flex-grow: 1;
    list-style: none;
    padding: 0;
    
}

.TransparencyExpansiveList_button_layout__KB3ml{
    background-color: #5b95f9;
}

.TransparencyExpansiveList_button_layout__KB3ml:hover{
    background-color: #8ca9de;
}

.TransparencyExpansiveList_selected_button__dgQQS{
    background-color: #295aae;
}

.TransparencyExpansiveList_selected_button__dgQQS:hover{
    background-color: #295aae;
}

.TransparencyExpansiveList_namedocs__l6vMV{
    opacity: 1;
    transition: opacity 0.3s ease;

}
.TransparencyExpansiveList_namedocs__l6vMV:hover{
    opacity: 0.5;

}

.TransparencyExpansiveList_custom_button__KIkKq {
    background-color: #ffffff;
    color: black;
    padding: 10px 50px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, opacity 0.3s;
    min-width: 80%;
}

.TransparencyExpansiveList_custom_button__KIkKq:hover {
    opacity: 0.85;
}

.TransparencyExpansiveList_topico_roleta__BWjXp{
    align-items: center;
}

.TransparencyExpansiveList_centerroulette__nzTf9 {
    max-width: 100%;
    text-align: center;
    margin: 0 auto; /* Isso centralizará a div horizontalmente */
}

.TransparencyExpansiveList_buttonClass__jRpr7{
    margin: 5px;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.TransparencyExpansiveList_title_docs__rchbz{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/transparency/TransparencyExpansiveList.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,UAAU;;AAEd;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,6BAA6B;;AAEjC;AACA;IACI,YAAY;;AAEhB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,+DAA+D;IAC/D,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,cAAc,EAAE,4CAA4C;AAChE;;AAEA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".expansive_layout{\r\n    flex-grow: 1;\r\n    list-style: none;\r\n    padding: 0;\r\n    \r\n}\r\n\r\n.button_layout{\r\n    background-color: #5b95f9;\r\n}\r\n\r\n.button_layout:hover{\r\n    background-color: #8ca9de;\r\n}\r\n\r\n.selected_button{\r\n    background-color: #295aae;\r\n}\r\n\r\n.selected_button:hover{\r\n    background-color: #295aae;\r\n}\r\n\r\n.namedocs{\r\n    opacity: 1;\r\n    transition: opacity 0.3s ease;\r\n\r\n}\r\n.namedocs:hover{\r\n    opacity: 0.5;\r\n\r\n}\r\n\r\n.custom_button {\r\n    background-color: #ffffff;\r\n    color: black;\r\n    padding: 10px 50px;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s, transform 0.3s, opacity 0.3s;\r\n    min-width: 80%;\r\n}\r\n\r\n.custom_button:hover {\r\n    opacity: 0.85;\r\n}\r\n\r\n.topico_roleta{\r\n    align-items: center;\r\n}\r\n\r\n.centerroulette {\r\n    max-width: 100%;\r\n    text-align: center;\r\n    margin: 0 auto; /* Isso centralizará a div horizontalmente */\r\n}\r\n\r\n.buttonClass{\r\n    margin: 5px;\r\n    color: #fff;\r\n    padding: 10px 15px;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n}\r\n\r\n.title_docs{\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expansive_layout": `TransparencyExpansiveList_expansive_layout__N1M0y`,
	"button_layout": `TransparencyExpansiveList_button_layout__KB3ml`,
	"selected_button": `TransparencyExpansiveList_selected_button__dgQQS`,
	"namedocs": `TransparencyExpansiveList_namedocs__l6vMV`,
	"custom_button": `TransparencyExpansiveList_custom_button__KIkKq`,
	"topico_roleta": `TransparencyExpansiveList_topico_roleta__BWjXp`,
	"centerroulette": `TransparencyExpansiveList_centerroulette__nzTf9`,
	"buttonClass": `TransparencyExpansiveList_buttonClass__jRpr7`,
	"title_docs": `TransparencyExpansiveList_title_docs__rchbz`
};
export default ___CSS_LOADER_EXPORT___;
