// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_home_background__TbZjr {
    color: var(--text-color);
    background-image: var(--background-image);
    background-size: var(--background-size);
    background-position: var(--background-position);
    display: var(--display);
    flex-direction: var(--flex-direction);
    align-items: var(--align-items);
    min-height: var(--min-height);
}

.Home_home_container__n9\\+KO {
    background-color: var(--background-color);
    display: var(--display);
    flex-direction: var(--flex-direction);
    align-items: var(--align-items);
    justify-content: var(--justify-content);
    margin: var(--margin);
    padding: var(--padding);
    min-width: var(--min-width);
    max-width: var(--max-width);
    flex-grow: var(--flex-grow);
}

.Home_home_container__n9\\+KO h1 {
    font-size: 2.5em;
    margin-bottom: 1em;
}

.Home_home_container__n9\\+KO h1 span {
    color: rgb(35, 131, 227);
    padding: 0.2em;
    background-color: #282c34;
}

.Home_home_container__n9\\+KO p {
    margin-bottom: 0.5em;
}

.Home_home_container__n9\\+KO img {
    width: 350px;
    margin: 2em 0;
}`, "",{"version":3,"sources":["webpack://./src/paginas/Home.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,yCAAyC;IACzC,uCAAuC;IACvC,+CAA+C;IAC/C,uBAAuB;IACvB,qCAAqC;IACrC,+BAA+B;IAC/B,6BAA6B;AACjC;;AAEA;IACI,yCAAyC;IACzC,uBAAuB;IACvB,qCAAqC;IACrC,+BAA+B;IAC/B,uCAAuC;IACvC,qBAAqB;IACrB,uBAAuB;IACvB,2BAA2B;IAC3B,2BAA2B;IAC3B,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".home_background {\r\n    color: var(--text-color);\r\n    background-image: var(--background-image);\r\n    background-size: var(--background-size);\r\n    background-position: var(--background-position);\r\n    display: var(--display);\r\n    flex-direction: var(--flex-direction);\r\n    align-items: var(--align-items);\r\n    min-height: var(--min-height);\r\n}\r\n\r\n.home_container {\r\n    background-color: var(--background-color);\r\n    display: var(--display);\r\n    flex-direction: var(--flex-direction);\r\n    align-items: var(--align-items);\r\n    justify-content: var(--justify-content);\r\n    margin: var(--margin);\r\n    padding: var(--padding);\r\n    min-width: var(--min-width);\r\n    max-width: var(--max-width);\r\n    flex-grow: var(--flex-grow);\r\n}\r\n\r\n.home_container h1 {\r\n    font-size: 2.5em;\r\n    margin-bottom: 1em;\r\n}\r\n\r\n.home_container h1 span {\r\n    color: rgb(35, 131, 227);\r\n    padding: 0.2em;\r\n    background-color: #282c34;\r\n}\r\n\r\n.home_container p {\r\n    margin-bottom: 0.5em;\r\n}\r\n\r\n.home_container img {\r\n    width: 350px;\r\n    margin: 2em 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home_background": `Home_home_background__TbZjr`,
	"home_container": `Home_home_container__n9+KO`
};
export default ___CSS_LOADER_EXPORT___;
