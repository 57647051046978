import React from 'react';
import styles from './RouletteCardBoard.module.css'; // Importe o arquivo CSS

const RouletteCardBoard = ({ card }) => {
  return (
    <div className={styles.card}> {/* Use a classe CSS do arquivo CSS */}
      <h3>{card.funcao}</h3>
      <img src={card.foto} alt={card.funcao} />
      <p>{card.cargo}</p>
    </div>
  );
};

export default RouletteCardBoard;