import styles from './ItemList.module.css';
import React from 'react';
import { AiFillCaretDown } from "react-icons/ai";
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";

const ItemList = ({ item, expandido, onExpandirItem }) => {
  return (
    <li className={styles.list}>
      <div className={styles.card}>
        <button className={styles.button} onClick={() => onExpandirItem(item.id)}>
          {expandido ? (
            <p className={styles.namedocs}>
              <MdExpandLess />
            </p>
          ) : (
            <p className={styles.namedocs}>
              <MdExpandMore />
            </p>
          )}
        </button>
        <span className={styles.namedocs} onClick={() => onExpandirItem(item.id)}>
          {item.titulo}
        </span>
        {expandido && (
          <div className={styles.content}>
            <AiFillCaretDown className={styles.iconColor} />
            {item.conteudo}
          </div>
        )}
      </div>
    </li>
  );
};

export default ItemList;