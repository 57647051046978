import React from 'react';
import RouletteCardBoard from './RouletteCardBoard.js';
import styles from './CardRenderBoard.module.css'; // Importando o arquivo CSS

const CardRenderBoard = ({ cards }) => {
  return (
    <div className={styles.cardRender}>
      {cards.map((card, index) => (
        <RouletteCardBoard key={index} card={card} />
      ))}
    </div>
  );
};

export default CardRenderBoard;