import { useState, useEffect } from 'react';
import styles from './Theme.module.css';

export const useTheme = () => {
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('theme') || 'light';
  });

  useEffect(() => {
    // Remove a classe anterior e adiciona a nova classe de tema
    document.body.classList.remove(styles.light, styles.dark);
    document.body.classList.add(styles[theme]);

    // Salva o tema no localStorage
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return { theme, toggleTheme };
};