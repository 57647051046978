import React from 'react';
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { FaFacebook, FaInstagram, FaUserClock, FaMapMarkedAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import styles from './Footer.module.css';

function Footer({ darkMode }) {  // Recebe o darkMode como prop

    return (
        <footer className={darkMode ? styles.container_night : styles.container_light}> {/* Aplica a classe baseada no tema */}
            <div className={styles.container}>
                <div className={styles.list_left}>
                    <ul>
                        <li className={styles.social_container}>
                            <div>
                                Centro Espirita Fonte Viva
                            </div>
                            <div>
                                <a href="https://www.facebook.com/Fontevivavotuporanga1/" target="_blank" rel="noopener noreferrer"> 
                                    <FaFacebook className={styles.social_icon} />
                                </a>
                                <a href="https://www.instagram.com/ge.fonteviva/" target="_blank" rel="noopener noreferrer"> 
                                    <FaInstagram className={styles.social_icon} />
                                </a>
                            </div>
                        </li>

                        <li className={styles.social_container}>
                            <div>
                                Centro de Educação Infantil Fonte Viva
                            </div>
                            <div>
                                <a href="https://www.facebook.com/profile.php?id=100071797126738&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"> 
                                    <FaFacebook className={styles.social_icon} />
                                </a>
                                <a href="https://www.instagram.com/cei.fonteviva" target="_blank" rel="noopener noreferrer"> 
                                    <FaInstagram className={styles.social_icon} />
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className={styles.list_right}>
                    <ul>
                        <li className={styles.information_container}>
                            <span>
                                <FaUserClock className={styles.item_icon} /> Atendimento ao público das 07h às 17h, de segunda a sexta-feira
                            </span>
                        </li>

                        <li className={styles.information_container}>
                            <span>
                                <FaMapMarkedAlt className={styles.item_icon} /> Rua Vicente Castrequini, nº 3717 - Bairro: Jardim Planalto - CEP: 15501-105
                            </span>
                        </li>

                        <li className={styles.information_container}>
                            <span>
                                <FaPhoneAlt className={styles.item_icon} /> 17 99607-7665
                            </span>
                        </li>

                        <li className={styles.information_container}>
                            <span>
                                <FaEnvelope className={styles.item_icon} /> fontevivaeducacaoinfantil@gmail.com
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.copyright_container}>
                <li className={styles.copyright_container}>
                    <span>
                        <AiOutlineCopyrightCircle /> Copyright: FonteViva 2023-2024
                    </span>
                </li>
            </div>

        </footer>
    );
}

export default Footer