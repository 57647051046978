// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.docs_docs_item__iVeBR {
    margin-left: 35px;
    margin-top: -45px;
    min-height: 40%;
}

.docs_custom_button__sPCG\\+ {
    background-color: rgb(200, 200, 200);
    min-height: 40%;
    color: black;
    padding: 10px 50px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.1s, transform 0.1s, opacity 0.1s;
}

.docs_custom_button__sPCG\\+:hover {
    background-color: rgb(175, 175, 175);
    transform: scale(1.01);
    min-height: 40%;
}`, "",{"version":3,"sources":["webpack://./src/docs/docs.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,+DAA+D;AACnE;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,eAAe;AACnB","sourcesContent":[".docs_item {\r\n    margin-left: 35px;\r\n    margin-top: -45px;\r\n    min-height: 40%;\r\n}\r\n\r\n.custom_button {\r\n    background-color: rgb(200, 200, 200);\r\n    min-height: 40%;\r\n    color: black;\r\n    padding: 10px 50px;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    transition: background-color 0.1s, transform 0.1s, opacity 0.1s;\r\n}\r\n\r\n.custom_button:hover {\r\n    background-color: rgb(175, 175, 175);\r\n    transform: scale(1.01);\r\n    min-height: 40%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"docs_item": `docs_docs_item__iVeBR`,
	"custom_button": `docs_custom_button__sPCG+`
};
export default ___CSS_LOADER_EXPORT___;
