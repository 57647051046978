// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemList_list__trC-l {
  list-style: none;
  margin: 0;
  padding: 0;
}
  
.ItemList_card__7IJ9U {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
  
.ItemList_card__7IJ9U:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
  
.ItemList_button__v6Q9G {
  border: none;
  background: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
  
.ItemList_namedocs__TrRim {
  font-size: 22px;
  color: #007bff;
  cursor: pointer;
  padding: 10px 0;
  -webkit-user-select: none;
          user-select: none;
}
  
.ItemList_namedocs__TrRim:hover {
  color: #0056b3;
  -webkit-user-select: none;
          user-select: none;
}
  
.ItemList_content__NnBCZ {
  padding-top: 10px;
  font-size: 18px;
  color: #555;
}
  
.ItemList_iconColor__qjl1l {
  color: #007bff;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/transparency/ItemList.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,qDAAqD;AACvD;;AAEA;EACE,2BAA2B;EAC3B,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,eAAe;EACf,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".list {\r\n  list-style: none;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n  \r\n.card {\r\n  background-color: #fff;\r\n  border-radius: 8px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n  padding: 20px;\r\n  margin-bottom: 15px;\r\n  transition: transform 0.3s ease, box-shadow 0.3s ease;\r\n}\r\n  \r\n.card:hover {\r\n  transform: translateY(-5px);\r\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\r\n}\r\n  \r\n.button {\r\n  border: none;\r\n  background: none;\r\n  font-size: 24px;\r\n  color: #333;\r\n  cursor: pointer;\r\n  user-select: none;\r\n}\r\n  \r\n.namedocs {\r\n  font-size: 22px;\r\n  color: #007bff;\r\n  cursor: pointer;\r\n  padding: 10px 0;\r\n  user-select: none;\r\n}\r\n  \r\n.namedocs:hover {\r\n  color: #0056b3;\r\n  user-select: none;\r\n}\r\n  \r\n.content {\r\n  padding-top: 10px;\r\n  font-size: 18px;\r\n  color: #555;\r\n}\r\n  \r\n.iconColor {\r\n  color: #007bff;\r\n  margin-right: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `ItemList_list__trC-l`,
	"card": `ItemList_card__7IJ9U`,
	"button": `ItemList_button__v6Q9G`,
	"namedocs": `ItemList_namedocs__TrRim`,
	"content": `ItemList_content__NnBCZ`,
	"iconColor": `ItemList_iconColor__qjl1l`
};
export default ___CSS_LOADER_EXPORT___;
